import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CourseVideo from "../Pages/course-details/CourseVideo";
import StreamVideo from "../Pages/course-details/StreamVideo";
import HomePage from "../Pages/home/HomePage";
import Explore from "../Pages/explore/Explore";
import MyCourses from "../Pages/myCourses/MyCourses";
import ProfileFile from "../Pages/profile/ProfileFile";
import StudentDetails from "../Pages/StudentDetails/StudentDetails";
import PersonalDetails from "../Pages/personalFile/PersonalDetails";
import UpdatePassword from "../Pages/updatePassword/UpdatePassword";
import EditEducationalLevel from "../Pages/educationLevel/EditEducationalLevel";
import EditCourse from "../Pages/courses/EditCourse";
import ParentsStudentInfo from "../Pages/ParentsStudentInfo/ParentsStudentInfo";
import ExploreInside from "../Pages/exploreInside/ExploreInside";
import DetailsCard from "../Pages/detailsCard/DetailsCard";
import PlayVideo from "../Pages/videoCourse/PlayVideo";
import VideoCourse from "../Pages/videoCourse/VideoCourse";
import InfoForm from "../Pages/info/InfoForm";
import TestsList from "../Pages/tests-list/tests-list";
import TestAndQuizPage from "../Pages/tests-list/testAndQuizPage";
import TestQuestions from "../Pages/tests-list/testQuestions";
import TestSubmitPage from "../Pages/tests-list/testSubmitPage";
import TestSubmit from "../Pages/tests-list/testSubmit";

const AdminRouting = () => {
  return (
    <>
      <Routes>
        <Route path="/home" element={<HomePage></HomePage>}></Route>
        <Route path="/course-details/:id" element={<CourseVideo />}></Route>
        <Route path="/stream-chapter" element={<StreamVideo />}></Route>
        <Route path="/explore" element={<Explore></Explore>}></Route>
        <Route path="/myCourses" element={<MyCourses></MyCourses>}></Route>
        <Route path="/profile" element={<ProfileFile></ProfileFile>}></Route>
        <Route
          path="/personalFile/:id"
          element={<PersonalDetails></PersonalDetails>}
        ></Route>
        <Route
          path="/studentDetails/:id"
          element={<StudentDetails></StudentDetails>}
        ></Route>
        <Route
          path="/updatePassword/:id"
          element={<UpdatePassword></UpdatePassword>}
        ></Route>
        <Route
          path="/editEducationalLevel/:id"
          element={<EditEducationalLevel></EditEducationalLevel>}
        ></Route>
        <Route
          path="/editCourses/:id"
          element={<EditCourse></EditCourse>}
        ></Route>

        <Route
          path="/parentsStudentInfo/:id"
          element={<ParentsStudentInfo></ParentsStudentInfo>}
        ></Route>

        <Route
          path="/exploreCourses/:tid"
          element={<DetailsCard></DetailsCard>}
        ></Route>
        <Route path="/testslist" element={<TestsList></TestsList>}></Route>
        <Route
          path="/quiz/testDetails/:id"
          element={<TestsList></TestsList>}
        ></Route>
        <Route
          path="/testsquizpage"
          element={<TestAndQuizPage></TestAndQuizPage>}
        ></Route>
        <Route
          path="/testsquizsubmit"
          element={<TestSubmitPage></TestSubmitPage>}
        ></Route>
        <Route path="/testsubmit" element={<TestSubmit></TestSubmit>}></Route>
        <Route
          path="/quiz/start/:id"
          element={<TestAndQuizPage></TestAndQuizPage>}
        ></Route>
        <Route
          path="quiz/test-questions/:id"
          element={<TestQuestions></TestQuestions>}
        ></Route>

        <Route
          path="/test-questions"
          element={<TestQuestions></TestQuestions>}
        ></Route>

        <Route
          path="/explore/:cid"
          element={<ExploreInside></ExploreInside>}
        ></Route>

        <Route
          path="/courseDetails/:id"
          element={<VideoCourse></VideoCourse>}
        ></Route>

        <Route path="/playVideo" element={<PlayVideo></PlayVideo>}></Route>

        <Route path="/educational-info" element={<InfoForm></InfoForm>}></Route>
      </Routes>
    </>
  );
};

export default AdminRouting;
