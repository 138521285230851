import React from "react";
import { Carousel } from "react-bootstrap";
import "./Home.scss";

const HomeSlide = (props) => {
  const bannersData = props.data;
  return (
    <>
      <Carousel variant="dark" className="border-theme-gray-13 rounded-3">
        {bannersData.map((banner) => (
          <Carousel.Item key={banner._id} className="mb-0">
            <a
              href={banner.link || undefined}
              target={banner.link ? "_blank" : undefined}
              onClick={(e) => {
                if (!banner.link) e.preventDefault();
              }}
              rel="noreferrer"
            >
              <img
                className="slider-item"
                src={banner.media ? banner.media : "/logo192.png"}
                alt={banner.name ? banner.name : "Banner"}
              />
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default HomeSlide;
