import React, { useState } from "react";
import { MdExplore } from "react-icons/md";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { RiShieldUserFill } from "react-icons/ri";
import { AiFillHome } from "react-icons/ai";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const FooterApp = () => {
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const openModal = () => {
    setFullscreen(true);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setFullscreen(false);
  };
  const logout = () => {
    localStorage.clear();
    navigate("/signup");
  };
  return (
    <>
      <div className="row p-0 m-0 stickyFooter-bottom fw-bold bg-light border-top theme-shadow ">
        {pathName === "/user/profile" && (
          <div className="align-items-center bg-light border d-flex justify-content-center w-100 logout-container">
            <Button
              variant="danger"
              className="col-12 col-md-4 col-lg-4 m-2"
              onClick={openModal}
            >
              LOG OUT
            </Button>
          </div>
        )}

        <div
          className={
            pathName === "/user/home"
              ? "text-light bg-button-color-1 col-3 flex-center "
              : "color-button-color-1 col-3 flex-center "
          }
          onClick={() => navigate("/user/home")}
        >
          <AiFillHome className="font-size-3x ft-icon" />
        </div>
        <div
          className={
            pathName === "/user/explore" || pathName.slice(0, 8) === "/explore"
              ? "text-light bg-button-color-1 col-3 flex-center"
              : "color-button-color-1 col-3 flex-center"
          }
          onClick={() => navigate("/user/explore")}
        >
          <MdExplore className="font-size-3x" />
        </div>
        <div
          className={
            pathName === "/user/mycourses"
              ? "text-light bg-button-color-1 col-3 flex-center"
              : "color-button-color-1 col-3 flex-center"
          }
          onClick={() => navigate("/user/mycourses")}
        >
          <BsFillJournalBookmarkFill className="font-size-3x" />
        </div>
        <div
          className={
            pathName === "/user/profile"
              ? "text-light bg-button-color-1 col-3 flex-center"
              : "color-button-color-1 col-3 flex-center"
          }
          onClick={() => navigate("/user/profile")}
        >
          <RiShieldUserFill className="font-size-3x" />
        </div>
      </div>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <div className="modal-content logout-modal">
          <Modal.Body className="align-items-center d-flex flex-column font-size-2x fw-bold justify-content-center modal-body text-light">
            Are you sure you want to logout ?
            <div className="d-flex flex-column align-items-center position-absolute bottom-0 pb-5 w-100">
              <Button
                variant="danger"
                className=" btn btn-light w-50 "
                onClick={logout}
              >
                LOG OUT
              </Button>

              <Button
                className=" btn bg-transparent btn-success   w-50 mt-1"
                onClick={closeModal}
              >
                CANCEL
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default FooterApp;
