import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import axiosInstance from "../../Hooks/api/Api";
import { errorMsg } from "../../Helpers/Error";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import Loader from "../../components/Loader/Loader";
import FooterApp from "../../components/appAdminLayout/FooterApp";

import HomeCard from "./HomeCard";
import HomeSlide from "./HomeSlide";
import HomeStories from "./HomeStories";
import { useNavigate, NavLink } from "react-router-dom";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";

const HomePage = () => {
  const navigate = useNavigate();

  const pageName = "Home";
  const newArray = [
    {
      _id: "3-1",
      item: [
        {
          description: "Coding",
          name: "Coding",
          _id: "1",
        },
      ],
      test: "Information Technology & Communication",
    },
  ];
  const [currentCourses, setCurrentCourses] = useState([]);
  const [categoriesTopics, setCategoriesTopic] = useState([]);
  const [testAndQuizzes, setTestAndQuizzes] = useState([]);
  const [banners, setBanners] = useState([]);
  const [error, setError] = useState("");
  const [searchData, setSearchData] = useState({});
  const [loader, setLoader] = useState(false);
  const getCurrentCourses = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/onGoingCourses", search)
      .then((response) => {
        setCurrentCourses(response.data.data);
        getBanners(search);
        getCategoriesTopics();
        getTestAndQuizzes();
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const getCategoriesTopics = () => {
    axiosInstance.get("courses/getCategoryTopics").then((response) => {
      setCategoriesTopic(response.data.data);
      console.log(response);
    });
  };
  const getTestAndQuizzes = () => {
    axiosInstance.post("quiz/listStandaloneQuizes").then((response) => {
      console.log(response.data.data);
      setTestAndQuizzes(response.data.data);
    });
  };

  const getBanners = (search) => {
    axiosInstance.post("banners/bannerList", search).then((response) => {
      setBanners(response.data.data);
    });
  };

  useEffect(() => {
    window. scrollTo({top: 0, left: 0, behavior: 'smooth' });
    getCurrentCourses(searchData);
  }, []);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}

        <HeaderApp pageName={pageName}></HeaderApp>

        <Container id="homePage" fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100">
            <Card.Body>
              <HomeSlide data={banners}></HomeSlide>

              <div>
                {currentCourses.length !== 0 && (
                  <h6 className=" fw-bold color-theme-color-1 mt-2">
                    Ongoing Courses
                  </h6>
                )}

                <div
                  className="d-flex overflow-auto mt-2"
                  style={{ gap: "10px" }}
                >
                  {currentCourses.map((course) => (
                    <HomeCard
                      id={course._id}
                      key={course._id}
                      image={course.media}
                      name={course.name}
                      cls={course.category.name}
                      subject={course.topic.name}
                    ></HomeCard>
                  ))}
                </div>
              </div>
              <div className="my-2 pb-2">
                {categoriesTopics.map((topic) => (
                  <div
                    className="d-flex flex-column align-items-start mb-2"
                    key={topic["_id"]}
                  >
                    <h6 className="color-button-color-1 fw-bold elem-max-bgr-w-300">
                      {topic["name"] ? topic["name"] : "NA"}
                    </h6>
                    <div className="d-flex justify-content-start flex-wrap gap-2">
                      {topic["topics"].map((item) => (
                        <HomeStories
                          courseID={item["_id"]}
                          key={item["_id"]}
                          name={item["name"]}
                          quiz={false}
                        ></HomeStories>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="mt-3 pb-2">
                  <h6 className=" fw-bold color-button-color-1 ">
                    Test & Quizzes
                  </h6>
                  {testAndQuizzes.map((test) => (
                    <div
                      className="d-flex flex-column align-items-start mb-2"
                      key={test["_id"]}
                    >
                      <h6 className="color-button-color-1 fw-bold elem-max-bgr-w-195">
                        {test["name"] ? test["name"] : "NA"}
                      </h6>
                      <div className="d-flex justify-content-start flex-wrap gap-2">
                        {test["topics"].map((item) => (
                          <HomeStories
                            courseID={item["topicID"]}
                            key={item["_id"]}
                            name={item["name"]}
                            quiz={true}
                          ></HomeStories>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default HomePage;
