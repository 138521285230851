import { Card, Container } from "react-bootstrap";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import Loader from "../../components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";

const TestAndQuizPage = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const pageName = "Test & Quiz";
  const [quizDetails, setQuizDetails] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  let { id } = useParams();
  const startTest = (quizId) => {
    setLoader(true);
    axiosInstance.post("/quiz/startTest/" + quizId).then((response) => {
      console.log(response.data.data);
      const testData = response.data.data;
      setTestDetails(response.data.data);
      const questions = testData['questions'].length > 0 ? testData['questions'][0] : testData['questions'];
      if(questions['questions']){
        questions['questions'] = questions['questions'].map((el) => { return {
          _id: el._id,
          title: el.title,
          description : el.description,
          options : el.options
        }
      } )
      }
      const quizRecord = {title: testData.quiz.title,_id :  testData.quiz._id,description : testData.quiz.description,duration : testData.quiz.duration};
      localStorage.removeItem("testData");
      const questionRecord = {
        testID : testData.testID,
        record : quizRecord,
        questions : questions
      }
      localStorage.setItem("testData",JSON.stringify(questionRecord));
      setLoader(false);
      navigate("/user/quiz/test-questions/" + testData.testID);
    });
  };
  const getquizDetails = () => {
    setLoader(true);
    axiosInstance.post("/quiz/getQuizDetails/" + id).then((response) => {
      setLoader(false);
      setQuizDetails(response.data.data);
    });
  };

  useEffect(() => {
    getquizDetails();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <h3 className="mb-0">
              {quizDetails["record"] && quizDetails["record"]["title"]
                ? quizDetails["record"]["title"]
                : "..."}
            </h3>
            <hr className="bg-theme-gray-1"></hr>
            <div className="test-details mb-2">
              <h6 className="color-theme-color-2 font-size-3x">
                Test or Quiz Details
              </h6>
              <div className="test-data px-3">
                <h6 className="font-size-2x color-theme-gray-8">
                  Total Questions :
                  {quizDetails["record"] && quizDetails["record"]["questions"]
                    ? quizDetails["record"]["questions"]
                    : "NA"}
                </h6>
                <h6 className="font-size-2x color-theme-gray-8">
                  Duration :
                  {quizDetails["record"] && quizDetails["record"]["duration"]
                    ? quizDetails["record"]["duration"]
                    : "NA"}{" "}
                  Mins
                </h6>
                <h6 className="font-size-2x color-theme-gray-8">
                  Passing Marks(%) :
                  {quizDetails["record"] &&
                  quizDetails["record"]["passing_marks"]
                    ? quizDetails["record"]["passing_marks"]
                    : "NA"}
                </h6>
                <h6 className="font-size-2x color-theme-gray-8">
                  Negative Marking :
                  {quizDetails["record"] &&
                  !quizDetails["record"]["negative_marking"]
                    ? "No"
                    : "Yes"}
                </h6>
              </div>
            </div>
            <div className="special-instrn mb-2">
              <h6 className="color-theme-color-2 font-size-3x">
                Special Instructions
              </h6>
              <div className="px-3">
                <p className="color-theme-gray-8">
                  {quizDetails["record"] && quizDetails["record"]["description"]
                    ? quizDetails["record"]["description"]
                    : "..."}
                </p>
              </div>
            </div>
            <div className="d-flex testFixedBottomBtn justify-content-end">
              <button
                className="btn btn-sm rounded-1 btn-button-color-1 text-light"
                onClick={() => startTest(quizDetails["record"]["_id"])}
              >
                Start Test
                <AiOutlineRight className="ms-1 font-size-2x"></AiOutlineRight>
              </button>
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default TestAndQuizPage;
