import { Card, Container } from "react-bootstrap";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import Loader from "../../components/Loader/Loader";
import React, { useState, useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import axiosInstance from "../../Hooks/api/Api";
import ErrorToast from "../../components/errorMsg/ErrorToast";

const TestQuestions = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [questionAnswer, setQuestionAnswer,answerRef] = useState({});
  //   const [timer, setTimer] = useState(questions.timer * 60);
  const [error, setError] = useState("");
  const pageName = "";
  const questions = localStorage.getItem("testData")
    ? JSON.parse(localStorage.getItem("testData"))
    : {};
  let { id } = useParams();
  const getTestDetails = () => {
    setLoader(true);
    axiosInstance.post("/quiz/getTestDetails/" + id).then((response) => {
      console.log(response.data);
      setLoader(false);
    });
  };
  useEffect(() => {
    getTestDetails();
  }, []);

  const nextQuestion = () => {
    console.log(questionAnswer);
    if(questions['questions']['questions'].length- 1 != currentQuestionIndex){
    if(selectedOptions[currentQuestionIndex] >= 0){
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex < questions['questions']['questions'].length - 1 ? prevIndex + 1 : prevIndex
      );
    }else{
      setError("Please select an answer to proceed");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    }else{
      localStorage.setItem("questionAnswer",JSON.stringify(questionAnswer)); 
      navigate("/user/testsquizsubmit/");
    }
  };
  const setSkipQuestion = () => {
    console.log(questions['questions']['questions'][currentQuestionIndex]['_id'],currentQuestionIndex);
  setQuestionAnswer({
    ...questionAnswer,
    [questions['questions']['questions'][currentQuestionIndex]['_id']] : ""
  });
}

  const skipQuestion = () => {
    setSkipQuestion();
    if(questions['questions']['questions'].length- 1 != currentQuestionIndex){
    setCurrentQuestionIndex((prevIndex) =>{
       return prevIndex < questions['questions']['questions'].length - 1 ? prevIndex + 1 : prevIndex
    }
    );
  }else{
      localStorage.setItem("questionAnswer",JSON.stringify(questionAnswer));  
      navigate("/user/testsquizsubmit/");
    }
  };

  const prevQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleOptionSelect = (questionIndex, optionIndex) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: optionIndex,
    });
    setQuestionAnswer({
      ...questionAnswer,
      [questions["questions"]["questions"][questionIndex]["_id"]]: optionIndex,
    });
  };

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader />}
        <HeaderApp pageName={pageName} />
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <h3 className="mb-0">
              {questions["record"] && questions["record"].title
                ? questions["record"].title
                : "NA"}
            </h3>
            <p className="mb-0 mt-1 color-theme-gray-10">
              {questions["record"] &&
              questions["record"]["sections"] &&
              questions["record"]["sections"].length > 0
                ? questions["record"]["sections"][0]
                : ""}
            </p>
            <hr className="bg-theme-gray-1" />
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  {currentQuestionIndex > 0 && (
                    <IoIosArrowBack
                      className="square-25 text-white bg-button-color-1 rounded-1 me-2"
                      onClick={prevQuestion}
                    ></IoIosArrowBack>
                  )}
                  <div className="font-size-3x color-theme-gray-8 font-weight-5x">
                    Ques#: {currentQuestionIndex + 1}
                  </div>
                </div>
                <div className="font-size-3x color-theme-gray-8 font-weight-5x">
                  Time: {questions["record"]["duration"]} Mins
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h5 className="font-size-3x">
                {" "}
                {
                  questions["questions"]["questions"][currentQuestionIndex][
                    "title"
                  ]
                }
              </h5>
              <div className="mb-2 mt-2 font-size-2x">
                Select the correct option:
              </div>

              <div className="options">
                {questions["questions"]["questions"][currentQuestionIndex][
                  "options"
                ].map((opt, idx) => (
                  <div key={idx} className="form-check mb-2 font-size-3x">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`option-${currentQuestionIndex}-${idx}`}
                      checked={selectedOptions[currentQuestionIndex] === idx}
                      onChange={() =>
                        handleOptionSelect(currentQuestionIndex, idx)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`option-${currentQuestionIndex}-${idx}`}
                    >
                      {opt}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4 optNextfixedBottom">
              {/* <button className="btn bg-theme-gray-13 font-size-2x">
                All Ques
              </button> */}
              <div className="d-flex">
                {currentQuestionIndex > 0 && (
                  <button
                    onClick={() => skipQuestion()}
                    className="btn bg-theme-gray-13 me-2 font-size-2x"
                  >
                    Skip Ques
                  </button>
                )}
                <button
                  onClick={() => nextQuestion()}
                  className="btn btn-button-color-1 font-size-2x color-theme-white"
                >
                  {currentQuestionIndex ===
                  questions["questions"]["questions"].length - 1
                    ? "Submit"
                    : "Save & Next"}
                  <AiOutlineRight className="ms-1 font-size-2x"></AiOutlineRight>
                </button>
              </div>
            </div>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};
export default TestQuestions;
