import React, { useEffect, useState } from "react";

import { Button, Card, Container, Image } from "react-bootstrap";

import { useNavigate, NavLink } from "react-router-dom";
import AppAppAccessBtn from "../../components/appAccessLayout/AppAccessBtn";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";
import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import Splash from "../../components/splash/Splash";

const SignUp = () => {
  const heading = "Sign Up";
  const heading_sub = "Create your account to start using Aarshi";
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [splash, setSplash] = useState(true);

  const userType = (e) => {
    const value = e.target.value;
    if (value === "student") {
      localStorage.setItem("type", value);
      navigate("/studentregister");
    }
    if (value === "parent") {
      localStorage.setItem("type", value);
      navigate("/register");
    }
  };

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/user/home");
    } else {
      setTimeout(() => {
        setSplash(false);
      }, 2000);
    }
  });

  return (
    <>
      <div className="main-wrapper">
        {splash && <Splash></Splash>}
        {loader && <Loader></Loader>}
        {!splash && (
          <Container fluid className="p-0 position-absolute bottom-0">
            <div className="d-flex justify-content-center">
              <Image
                fluid
                src={require("../../Assets/images/signIn.png")}
                className="elem-w-250 m-2"
              ></Image>
            </div>
            <Card
              className="br-top "
              style={{ height: "60vh", overflow: "auto" }}
            >
              <Card.Body className="d-flex align-items-center flex-column mt-4 m-2 noselect">
                <FormAccessLayout
                  className="d-flex align-items-left signup-name"
                  heading={heading}
                  heading_sub={heading_sub}
                ></FormAccessLayout>

                <Button
                  onClick={userType}
                  type="submit"
                  className="border-0  mt-2 col-12 col-md-5 col-xl-5 theme-shadow"
                  variant="button-color-1 color-theme-white"
                  id="studentButton"
                  value={"student"}
                >
                  Sign Up
                </Button>

                {/* <Button
                  onClick={userType}
                  type="submit"
                  className="border-0  mt-2 col-12 col-md-5 col-xl-5 theme-shadow "
                  variant="button-color-1 color-theme-white"
                  id="parentButton"
                  value={"parent"}
                >
                  I'm a Parent
                </Button> */}

                <div className="color-theme-gray-8 m-0 fw-small mb-4 mt-4">
                  OR
                </div>

                <Button
                  onClick={() => navigate("/login")}
                  type="submit"
                  className="border-0  mt-2 col-12 col-md-5 col-xl-5 theme-shadow"
                  variant="button-color-1 color-theme-white"
                  id="submitButton"
                >
                  Sign In
                </Button>

                <div className="text-dark fw-small mt-4 mt-30">
                  <small>
                    {" "}
                    By creating an account with Aarshi you agree to our
                  </small>
                </div>
                <NavLink
                  to={"/termsConditions"}
                  className="text-decoration-none fw-small small text-blue mb-3"
                >
                  {"Terms & Conditions"}
                </NavLink>
              </Card.Body>
            </Card>
          </Container>
        )}
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default SignUp;
