import { Card, Container } from "react-bootstrap";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import Loader from "../../components/Loader/Loader";
import React, { useState, useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import axiosInstance from "../../Hooks/api/Api";

const TestSubmitPage = () => {
  const [loader, setLoader] = useState(false);
  const [answerCount, setAnswerCount] = useState("0");
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();
  const pageName = "Test & Quiz";
  const questions = JSON.parse(localStorage.getItem("testData"));
  const questionAnswer = JSON.parse(localStorage.getItem("questionAnswer"));
  let questionKeys = [];

  if (questionAnswer) {
    questionKeys = Object.keys(questionAnswer);
    console.log(questionKeys);
  }
  const setAnswerObj = (key) => {
    console.log(key);
    setAnswers((answers) => ({
      ...answers,
      [key]: questionAnswer[key],
    }));
  };
  useEffect(() => {
    const answerData = questionKeys.filter((key) => questionAnswer[key] != "");
    console.log(answerData);
    answerData.map((key) => {
      if (questionAnswer[key] != "") {
        setAnswerObj(key);
      }
    });
    setAnswerCount(answerData.length);
  }, []);

  const submitAns = () => {
    setLoader(true);
    axiosInstance
      .post("/quiz/submitTest/" + questions["testID"], { answers: answers })
      .then((response) => {
        console.log(response.data);
        setLoader(false);
        navigate("/user/testsubmit");
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader />}
        <HeaderApp pageName={pageName} />
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <h3 className="mb-0">
              {questions["record"].title ? questions["record"].title : "NA"}
            </h3>
            <hr className="bg-theme-gray-1" />
            <div className="">
              <h5 className="color-theme-color-1">Test Status</h5>
              <h6 className="color-theme-gray-8">
                Answered Questions:{answerCount}
              </h6>
              <div className="d-flex mb-2">
                {questions['questions']['questions'].map((opt, idx) => (
                  <div
                    className={
                      questionAnswer[opt['_id']]
                        ? "ansBox bg-theme-color-1"
                        : "ansBox"
                    }
                    key={opt['_id']}
                  ></div>
                ))}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="btn btn-button-color-1 font-size-2x color-theme-white"
                  onClick={submitAns}
                >
                  Submit
                  <AiOutlineRight className="ms-1 font-size-2x"></AiOutlineRight>
                </button>
              </div>
              <div className="mt-3 color-theme-gray-8 text-center">
                Once you Submit this test, you will consume one attempt and this
                cannot be changed. please continue once you are stisfied with
                all your answers.
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default TestSubmitPage;
