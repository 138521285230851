import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";

import ErrorToast from "../../components/errorMsg/ErrorToast";
import Loader from "../../components/Loader/Loader";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { useNavigate } from "react-router-dom";
import { ImEqualizer } from "react-icons/im";
import { GrClose } from "react-icons/gr";

import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import axiosInstance from "../../Hooks/api/Api";
import { errorMsg } from "../../Helpers/Error";
import CourseType from "./CourseType";

const Explore = () => {
  const currentFilterData = JSON.parse(localStorage.getItem("filterData"));
  const pageName = "Explore";
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [coursesTypes, setcoursesTypes] = useState([]);
  const [searchData, setSearchData] = useState(
    currentFilterData ? currentFilterData : {}
  );
  const [filterCourses, setFilterCourses] = useState(
    currentFilterData ? currentFilterData.categories : []
  );
  const [filter, setFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [sort, setSort] = useState(1);

  const getCoursesTypes = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/courseTypes", search)
      .then((response) => {
        setcoursesTypes(response.data.data);
        console.log(response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const getCoursesOptions = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/courseTypes", search)
      .then((response) => {
        setFilterOptions(response.data.data);
        setLoader(false);

        for (let i = 0; i < response.data.data.length; i++) {
          const selectedCourses = response.data.data.filter(
            (item) => item["_id"] === filterCourses[i]
          );
          const selectedCourseID = document.getElementById(
            selectedCourses[0]["_id"]
          );

          if (selectedCourseID.id === selectedCourses[0]["_id"]) {
            selectedCourseID.checked = true;
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const openFilter = () => {
    getCoursesOptions({});
    document.getElementById("courseTypes").classList.add("open");
  };
  const closeFilter = () => {
    document.getElementById("courseTypes").classList.remove("open");
  };

  const clearData = () => {
    closeFilter();
    setSearchData({});
    setFilterCourses([]);
    getCoursesTypes(searchData);

    var checkboxes = document.getElementsByName("courses");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
  };

  const selectedCourses = (event) => {
    const { value, checked } = event.target;
    const selectedList = [...filterCourses];
    if (checked) {
      setFilterCourses([...selectedList, value]);
    } else {
      setFilterCourses(selectedList.filter((e) => e !== value));
    }
  };

  const handleFilter = () => {
    setSearchData({ ...searchData, categories: filterCourses });
    localStorage.setItem(
      "filterData",
      JSON.stringify({ categories: filterCourses })
    );
    setFilter(true);
  };

  const sortData = () => {
    setSearchData({ ...searchData, sort: sort });
  };

  useEffect(() => {
    if (filter && searchData["categories"]?.length > 0) {
      getCoursesTypes(searchData);

      closeFilter();
    }
    if (sort === 1) {
      setSort(-1);
      getCoursesTypes(searchData);
    }
    if (sort === -1) {
      setSort(1);
      getCoursesTypes(searchData);
    }
  }, [searchData]);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}

        <Card className="types-filter br-top" id="courseTypes">
          <div className="text-end c-pointer p-3" onClick={closeFilter}>
            <GrClose className="color-button-color-1"></GrClose>
          </div>
          <div className="d-flex flex-column align-items-start p-3">
            <h5 className="text-dark text-capitalize mb-0 fw-bold">
              interested in
            </h5>
            <small className="text-secondary text-capitalize">
              courses you are interested in
            </small>
            <div className="mt-3 w-100">
              {filterOptions.map((type, index) => (
                <Card key={type["_id"]} className="mb-2 theme-shadow">
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <label htmlFor={index} className="c-pointer">
                      {type["name"]}
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={type["_id"]}
                      name="courses"
                      value={type["_id"]}
                      onClick={selectedCourses}
                    ></input>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
          <div className="row w-100 position-absolute bottom-0 mx-0 p-0  border-button-color-1 text-uppercase align-items-center">
            <div
              className="col-6 color-button-color-1 text-center small elem-h-30 py-1 c-pointer"
              onClick={clearData}
              id="clearFilter"
            >
              clear
            </div>
            <div
              className="col-6 bg-button-color-1 text-light text-center small py-1 elem-h-30 c-pointer"
              onClick={handleFilter}
            >
              apply
            </div>
          </div>
        </Card>
        <HeaderApp pageName={pageName}></HeaderApp>

        <Container id="homePage" fluid className=" p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="text-capitalize color-button-color-1 fw-bold">
                courses types
              </h6>
              <div className="d-flex justify-content-center align-items-baseline gap-1">
                <div className="c-pointer" onClick={openFilter} id="filterBtn">
                  <ImEqualizer color="gray" />
                </div>
                <div
                  className=" fw-bold small color-button-color-1 c-pointer"
                  onClick={sortData}
                >
                  {sort === -1 ? "ZA" : "AZ"}
                </div>
              </div>
            </div>
            <div className="my-2 pb-4">
              {coursesTypes.map((type) => (
                <CourseType
                  key={type["_id"]}
                  name={type["name"]}
                  description={type.description}
                  id={type["_id"]}
                ></CourseType>
              ))}
            </div>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default Explore;
