import { Card, Container } from "react-bootstrap";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import Loader from "../../components/Loader/Loader";
import React, { useState, useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import axiosInstance from "../../Hooks/api/Api";
const TestSubmit = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [result, setResult] = useState({});
  const pageName = "Test & Quiz";
  const testData = JSON.parse(localStorage.getItem("testData"));
  const questions = {
    title: "C Programming Mock Test",
    type: "basics",
    timer: 30,
    questions: [
      {
        ques: "Who is the father of C language?",
        option: [
          { opt: "Steve Jobs" },
          { opt: "James Gosling" },
          { opt: "Dennis Ritchie" },
          { opt: "Rasmus Lerdorf" },
        ],
      },
      {
        ques: "which of the following is not a valid C variable name?",
        option: [
          { opt: "int number;" },
          { opt: "float rate;" },
          { opt: "int variable_count;" },
          { opt: "int $main;" },
        ],
      },
    ],
  };
  const backHome = () => {
    navigate("/user/home");
  };
  const getTestDetails = () => {
    setLoader(true);
    axiosInstance
      .post("/quiz/getTestDetails/" + testData["testID"])
      .then((response) => {
        console.log(response.data);
        setResult(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getTestDetails();
  }, []);
  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader />}
        <HeaderApp pageName={pageName} />
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <h3 className="mb-0">
              {testData["record"] && testData["record"]["title"]
                ? testData["record"]["title"]
                : "NA"}
            </h3>
            <hr className="bg-theme-gray-1" />
            <div className="">
              <p className="color-theme-gray-8 text-center mb-4">
                Your responses have been evaluated.
              </p>
              <div className="result-details text-center font-size-4x">
                <div className="text-danger">
                  {result && result["result"] && result["result"]["mark"]
                    ? result["result"]["mark"]
                    : ""}
                </div>
                <div className="color-theme-gray-8 text-uppercase">Correct</div>
                <div className="color-theme-gray-8 text-uppercase">
                  Result :
                  <span
                    className={
                      result && result["result"] && result["result"]["result"]
                        ? "text-primary ms-1 text-uppercase"
                        : "text-danger ms-1 text-uppercase"
                    }
                  >
                    {result && result["result"] && result["result"]["result"]
                      ? "Passed"
                      : "Failed"}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <button
                  className="btn btn-button-color-1 font-size-2x color-theme-white"
                  onClick={backHome}
                >
                  Back to Home
                </button>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default TestSubmit;
