import { IoIosArrowBack } from "react-icons/io";
import React, { useEffect,useState } from "react";
import { Card, Container } from "react-bootstrap";
import axiosInstance from "../../Hooks/api/Api";
import Loader from "../../components/Loader/Loader";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { useNavigate, useParams } from "react-router-dom";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import { AiOutlineRight } from "react-icons/ai";
import { Button, Modal } from "react-bootstrap";

const TestsList = () => {
  const navigate = useNavigate();
  const [selectedindex, setSelectedIndex] = useState("1");
  const [mockList, setMockList] = useState([]);
  const [quizDetails, setQuizDetails] = useState([]);
  const selectedCourse = (index) => {
    setSelectedIndex(index);
  };
  const [loader, setLoader] = useState(false);
  const pageName = "Home";
  const topicName = "Test's";
  let { id } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const startTest = (quizId) => {
    navigate("/user/quiz/start/"+quizId);
  };
  const getTestDetails = () => {
    setLoader(true);
    axiosInstance.post("/quiz/getStandaloneQuizes/"+id).then((response) => {
      console.log(response);
      setLoader(false);
      setMockList(response.data.data);
    });
  };
  const getQuizDetails = (quizId) => {
    setLoader(true);
    axiosInstance.post("quiz/getQuizDetails/"+quizId).then((response) => {
      console.log(response.data.data);
      setLoader(false);
      setQuizDetails(response.data.data);
      setOpenModal("true");
    });
  };

  useEffect(() => {
    window. scrollTo({top: 0, left: 0, behavior: 'smooth' });
    getTestDetails();
  }, []);

  
  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start align-items-center">
                <IoIosArrowBack
                  className="square-25 text-white bg-button-color-1 rounded-1 me-2"
                  onClick={() => navigate(-1)}
                ></IoIosArrowBack>
                <h6 className="text-capitalize color-button-color-1 mb-0 fw-bold">
                  {topicName}
                </h6>
              </div>
            </div>
            {mockList.length > 0 &&
              mockList.map((mock, index) => (
                <div
                  className="d-flex justify-content-start mb-2 mt-3 gap-3 align-items-center c-pointer"
                  key={mock["_id"]}
                  onClick={() => selectedCourse(mock["testNo"])}
                >
                    <h2
                          className="color-button-color-1 fw-bold font-size-8x"
                    >
                      {index >= 9 ? (index * 1 + 1) : "0" + (index* 1 + 1)}
                    </h2>
                  <div className="flex-grow-1">
                    <small className="fw-bold text-break color-button-color-1">
                      {mock["title"] ? mock["title"] : "NA"}
                    </small>
                    <small className="text-secondary append-ellipsis">
                      {mock["questions"] ? mock["questions"] : "NA"}{" "}
                      Questions | {mock["duration"] ? mock["duration"] : "NA"}{" "}
                      Mins
                    </small>
                  </div>
                  <button
                    className="btn btn-sm rounded-1 btn-button-color-1 text-light"
                    onClick={() => getQuizDetails(mock['_id'])}
                  >
                    <AiOutlineRight className="fs-6"></AiOutlineRight>
                  </button>
                </div>
              ))}
          </Card>
        </Container>
        <FooterApp></FooterApp>
      </div>
      <Modal
        show={openModal}
        size="md"
        className="confirmModal"
        onClose={() => setOpenModal(false)}
        popup="true"
      >
        <Modal.Header className="font-size-5x text-black border-bottom-0">
          Test & Quiz
        </Modal.Header>
        <Modal.Body>
          {quizDetails && quizDetails['record'] &&  (
          <div className="text-start">
            <h3 className="color-theme-color-1 font-size-3x">
              {quizDetails['record']['title']}
            </h3>
            <p className="color-theme-gray-10 mt-1">{quizDetails['record']['questions']} Questions | {quizDetails['record']['duration']} Mins</p>
              {quizDetails['result'] && !quizDetails['result']['attempts'] && (
            <h3 className="text-uppercase mb-4 color-theme-gray-10 font-size-3x">
                Result Not Avaiable
            </h3>
)}
 {quizDetails['result'] && quizDetails['result']['attempts'] > 0 && (
  <div>
           <h2 className="color-theme-color-1 font-size-3x">
           {quizDetails['result']['mark']}
         </h2>
         <p className="color-theme-gray-10 mt-1">CORRECT</p>
         <p className="color-theme-gray-10">RESULT : <span className={!quizDetails['result']['result'] ? "text-danger" : 'color-theme-color-1'}>{!quizDetails['result']['result'] ? "FAILED" : 'PASSED'}</span></p>
       </div>  
)}
            <div className="justify-content-end d-flex">
              <Button
                className="bg-theme-gray-13 border-0 text-black me-3"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
              <Button
                className={quizDetails['result'] && !quizDetails['record']['allow_re_test'] ? "bg-button-color-1 border-0 disabled" : "bg-button-color-1 border-0"}
                onClick={() => startTest(quizDetails['record']['_id'])}
              >
                {!quizDetails['result'] || !quizDetails['result']['attempts'] ? "Start Test Now" : "Re-Take Test"}
              </Button>
            </div>
          </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TestsList;
